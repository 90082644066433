<template>
  <TabGroup
    as="div"
    class="w-full"
    v-slot="{ selectedIndex }"
    :defaultIndex="0"
  >
    <TabList>
      <div class="flex">
        <div class="lg:basis-9/12 basis-full">
          <div class="w-full"></div>
          <div
            class="max-h-lg flex flex-col items-start justify-end relative p-4 min-h-16 h-56 bg-[#4F46E5]"
          >
            <MobileOptionsMenu />
            <img
              src="~/assets/images/login-top.png"
              class="absolute right-0 bottom-[-12px] opacity-10"
              alt=""
            />
            <h2
              class="font-normal text-4xl opacity-90 text-[#fff] leading-[1.15] font-['Comic_Neue']"
            >
              Edit Your Profile
            </h2>
            <div class="font-semibold fot-[Nunito Sans] text-white mt-2 text-[20px] opacity-60">
              {{ user.full_name }}
            </div>
          </div>

          <div>
            <div
              class="max-h-lg flex flex-col items-center justify-center relative min-h-16 h-auto rounded-xl mt-12"
            >
              <Tab class="absolute top-2 left-2" as="div">
                <icon
                  name="ic:round-arrow-back"
                  size="40"
                  class="p-1 cursor-pointer rounded-md hover:bg-base-300"
                  v-if="selectedIndex != 0"
                />
              </Tab>

              <div class="avatar" @click="openCropper = true">
                <div class="w-20 rounded-full">
                  <img :src="user.image" alt="Tailwind-CSS-Avatar-component" />
                </div>
              </div>
              <button
              type="button"
              class="mt-[20px] mx-auto flex group items-center gap-x-2.5 w-fit px-[20px] text-[14px] bg-[#4F46E5] text-white font-semibold py-[10px] rounded-2xl hover:bg-[#2905A1] font-['Nunito_Sans'] shadow-[0_10px_16px_0px_rgba(0,0,0,0.2)]"
              @click="openCropper = true"
              >
              Upload Photo
            </button>
            </div>
          </div>
          <div>
            <TabPanels class="mt-2 min-h-fit justify-center flex p-2">
              <transition
                enter-active-class="transition-all duration-300"
                leave-active-class="transition-all duration-300 "
                enter-from-class="opacity-0 -translate-x-10"
                leave-to-class="opacity-0 -translate-x-10"
                mode="out-in"
              >
                <TabPanel
                  class="rounded-xl basis-full flex justify-center mb-10"
                >
                  <ProfileMenu />
                </TabPanel>
              </transition>

              <transition
                v-for="tab in tabs"
                :key="tab.id"
                enter-active-class="transition-all duration-300"
                leave-active-class="transition-all duration-300 "
                enter-from-class="opacity-0 translate-x-10"
                leave-to-class="opacity-0 -translate-x-10"
                mode="out-in"
              >
                <TabPanel
                  class="rounded-xl basis-full flex justify-center mb-32"
                >
                  <component :is="tab.component" />
                </TabPanel>
              </transition>
            </TabPanels>
          </div>
        </div>
      </div>
    </TabList>
    <CropperModal
      :show="openCropper"
      @data="image = $event"
      @close="openCropper = false"
    />
  </TabGroup>
</template>

<script setup>
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from "@headlessui/vue";
import { useUserStore } from "~~/stores/user";
import { storeToRefs } from "pinia";
const userStore = useUserStore();
const { user } = storeToRefs(userStore);
const image = ref({});
const openCropper = ref(false);

const updateUserImage = async () => {
  try {
    await userStore.updateUserImage(image.value);
    await userStore.getUser();
    setTimeout(() => (openCropper.value = false), 300);
  } catch (error) {
    openCropper.value = false;
    alert(error);
  }
};
watch(
  () => image.value,
  async () => await updateUserImage()
);

const tabs = [
  {
    id: 1,
    name: "Bio",
    icon: "mdi:user",
    component: defineAsyncComponent(() => import("./BioSection.vue")),
  },
  {
    id: 2,
    name: "Contact",
    icon: "teenyicons:contact-solid",
    component: defineAsyncComponent(() => import("./ContactSection.vue")),
  },
  {
    id: 3,
    name: "Emergency",
    icon: "medical-icon:i-emergency",
    component: defineAsyncComponent(() =>
      import("./EmergencyContactsSection.vue")
    ),
  },
  {
    id: 4,
    name: "Vehicle",
    icon: "fluent:vehicle-cab-28-filled",
    component: defineAsyncComponent(() => import("./VehicleSection.vue")),
  },
  {
    id: 5,
    name: "Medical",
    icon: "bi:journal-medical",
    component: defineAsyncComponent(() => import("./MedicalSection.vue")),
  },
];
</script>
