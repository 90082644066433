<template>
<div class="gap-3 mt-5 py-2 w-full flex flex-col justify-center items-center">
    <Tab
    as="div"
    :key="tab.id"
    v-for="tab in tabs"
    class="focus:outline-none px-2 w-full flex justify-center "
    v-slot="{ selected }"
    >
    <button
        :class="[selected ? '' : 'text-neutral   border-primary border-2']"
        class="btn bg-[#4F46E5] text-white py-[12px] rounded-xl hover:bg-[#2905A1] disabled:opacity-20 shadow w-3/4 text-sm flex text-center items-center justify-between gap-2 font-medium cursor-pointer"
    >
        <div class="flex items-center gap-2">
        <icon :name="tab.icon" size="25" class="hidden lg:block md:block" />
        <span>{{ tab.name }}</span>
        </div>
        <div>
        <icon
            name="zondicons:cheveron-right"
            size="25"
            class="hidden lg:block md:block"
        />
        </div>
    </button>
    </Tab>
</div>
</template>

<script setup>
import { TabList, Tab } from "@headlessui/vue";

const tabs = [
{
    id: 1,
    name: "Personal",
    icon: "mdi:user",
    component: defineAsyncComponent(() => import("./BioSection.vue")),
},
{
    id: 2,
    name: "Contact",
    icon: "teenyicons:contact-solid",
    component: defineAsyncComponent(() => import("./ContactSection.vue")),
},
{
    id: 3,
    name: "Emergency",
    icon: "medical-icon:i-emergency",
    component: defineAsyncComponent(() => import("./EmergencyContactsSection.vue")),
},
{
    id: 4,
    name: "Vehicle",
    icon: "fluent:vehicle-cab-28-filled",
    component: defineAsyncComponent(() => import("./VehicleSection.vue")),
},
{
    id: 5,
    name: "Medical",
    icon: "bi:journal-medical",
    component: defineAsyncComponent(() => import("./MedicalSection.vue")),
},
];
</script>

<style scoped></style>
